import React from 'react';
import t from '../../lib/translate/index';
import ContentHeaderCms from '../../content-elements/contentHeaderCms';

const GuestbookHeader = () => (
  <ContentHeaderCms>
    <h1>{t`guestbook_title`}</h1>
  </ContentHeaderCms>
);
export default GuestbookHeader;
