import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import Section from '../../component-elements/content-section';
import t from '../../lib/translate/index';
import { useFormSubmit } from '../../lib/service/formSubmit/useFormSubmit';

const GuestForm = ({ locale, privacyLink }) => {
  const containerRef = useRef();

  const [active, setActive] = useState(false);

  const formSubmit = useFormSubmit(locale, 'guestbook');

  const onSubmit = async ({ privacy, appointmentdate, ...data }, { setSubmitting }) => {
    setSubmitting(true);

    const validationErrors = await formSubmit.mutateAsync({
      ...data,
      privacy: privacy === true ? 1 : -1,
    });

    setSubmitting(false);

    if (!validationErrors) {
      // no validation errors, we're done here
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      return;
    }

    // validation errors

    const validationMsg = Object.keys(validationErrors)
      .reduce((carry, key) => {
        carry.push(`${key}: ${validationErrors[key].join(' | ')}`);
        return carry;
      }, []).join('\n');

    // eslint-disable-next-line no-alert
    alert(validationMsg);
  };

  const privacyFieldLbl = t(
    'form_guestbook_privacy_label',
    `<a href="${privacyLink}" target="_blank" rel="noreferrer">${t('form_guestbook_privacy_link_label')}</a>`,
  );

  return (
    <>
      <Section background="Grau" ref={containerRef}>
        <div className="col-md-12">
          <p>{t`guestbook_text`}</p>
        </div>
        <div className="col-md-4 text-sm-right">
          {!active && (
            <button type="button" onClick={() => setActive(true)} className="btn btn-primary">
              {t`guestbook_button_enter`}
            </button>
          )}
          {active && !formSubmit.isSuccess && (
            <button type="button" onClick={() => setActive(false)} className="icon icon-close btn btn-link">
              <span className="sr-only">
                {t`guestbook_button_close`}
              </span>
            </button>
          )}
        </div>
        <div className="col-md-12">
          {active && (
            <>
              {formSubmit.isSuccess && (
                <>{t`guestbook_form_submit_success_txt`}</>
              )}

              {formSubmit.isLoading && (
                <>LOADING...</>
              )}

              {!formSubmit.isLoading && !formSubmit.isSuccess && (
                <Formik
                  initialValues={{
                    name: '',
                    subject: '',
                    email: '',
                    message: '',
                    privacy: false,
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(t`validation_err_required`),
                    subject: Yup.string().required(t`validation_err_required`),
                    email: Yup.string().email(t`validation_err_email`).required(t`validation_err_required`),
                    message: Yup.string().required(t`validation_err_required`),
                    privacy: Yup.boolean().oneOf([true], t`validation_err_required`).required(t`validation_err_required`),
                  })}
                  onSubmit={onSubmit}
                >
                  <Form
                    className="needs-validation"
                    style={{
                      marginTop: '2rem',
                    }}
                  >
                    <div className="form-group">
                      <label htmlFor="name">{`${t`form_guestbook_name_label`} *`}</label>
                      <Field className="form-control" type="text" name="name" id="name" placeholder={t`form_guestbook_name_label`} />
                      <ErrorMessage name="name">{ (msg) => <p>{msg}</p>}</ErrorMessage>
                    </div>
                    <div className="form-group">
                      <label htmlFor="lastname">{`${t`form_guestbook_subject_label`} *`}</label>
                      <Field className="form-control" type="text" name="subject" id="subject" placeholder={t`form_guestbook_subject_label`} />
                      <ErrorMessage name="subject" className="invalid-feedback">{ (msg) => <p>{msg}</p>}</ErrorMessage>
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">{`${t`form_guestbook_email_label`} *`}</label>
                      <Field className="form-control" type="email" name="email" id="email" placeholder={t`form_guestbook_email_label`} />
                      <ErrorMessage name="email">{ (msg) => <p>{msg}</p>}</ErrorMessage>
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">{`${t`form_guestbook_message_label`} *`}</label>
                      <Field className="form-control" name="message" as="textarea" rows="3" id="message" placeholder={t`form_guestbook_message_label`} />
                      <ErrorMessage name="message">{ (msg) => <p>{msg}</p>}</ErrorMessage>
                    </div>
                    <div className="form-group">
                      <div className="form-check">
                        <Field className="form-check-input" name="privacy" type="checkbox" id="privacy" />
                        {/* eslint-disable-next-line max-len */}
                        {/* eslint-disable-next-line react/no-danger,jsx-a11y/label-has-associated-control */}
                        <label className="form-check-label" htmlFor="privacy" dangerouslySetInnerHTML={{ __html: privacyFieldLbl }} />
                        <ErrorMessage name="privacy" id="privacy">
                          { (msg) => <p>{msg}</p>}
                        </ErrorMessage>
                      </div>
                    </div>
                    <p className="required-info">{t`form_required_fields`}</p>
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">{t`form_guestbook_button_send`}</button>
                    </div>
                  </Form>
                </Formik>
              )}
            </>
          )}
        </div>
      </Section>
    </>
  );
};

GuestForm.propTypes = {
  privacyLink: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};

export default GuestForm;
