import React from 'react';
import PropTypes from 'prop-types';
import Section from '../../component-elements/content-section';

import t from '../../lib/translate/index';

import * as commentsStyle from './component.module.scss';

const GuestbookComments = ({ comments }) => (
  <Section noGrid background="Weiß">
    <h5 className="counter-comments section-title">
      {`${comments.length} ${t`guestbook_number_entry`}`}
    </h5>
    {
      comments.map(({
        id,
        message,
        name,
        title,
        date,
      }) => (
        <div key={id} className={commentsStyle.commentsSection}>
          <p className={`${commentsStyle.commentsName} text-content`}>{`${name} vom ${date}`}</p>
          <h5 className={commentsStyle.commentsTitle}>{title}</h5>
          <p style={{ whiteSpace: 'pre-wrap' }} className="text-content">{message}</p>
          <hr />
        </div>
      ))
    }
  </Section>
);

GuestbookComments.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default GuestbookComments;
